import React from 'react';
import {
  CatalogFilters,
  CatalogSearchRequest,
  CatalogSort,
} from './interfaces';
import { useCatalogSearchExcludedFeature } from './useCatalogSearchExcludedFeature';

export function useCatalogSearchForRefund(filters?: Partial<CatalogFilters>) {
  const [queryFilters, setQueryFilters] = React.useState<
    Partial<CatalogFilters>
  >({});

  React.useEffect(() => setQueryFilters(filters), [JSON.stringify(filters)]);

  const requestParams = {
    filters: queryFilters,
    page: 1,
    page_size: 20,
    sort: CatalogSort.RELEVANCE,
  } as CatalogSearchRequest;

  // only enable the query if there are terms to search for
  const enabled = !!requestParams.filters.terms;

  return useCatalogSearchExcludedFeature(requestParams, enabled);
}
