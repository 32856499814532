import React from 'react';
import {
  DatePicker,
  LocalizationProvider,
  DatePickerProps,
} from '@mui/x-date-pickers';

import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTime } from 'luxon';

export interface CustomDatePickerProps {
  field: {
    onChange: (value: string | null) => void;
    onBlur?: () => void;
    [key: string]: any;
  };
  value: DateTime | null;
  slotProps?: DatePickerProps<any>['slotProps'];
}

export const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  field,
  value,
  slotProps = {},
}) => {

  const pickerRef = React.useRef<HTMLDivElement | null>(null);

  const handleBlur = () => {
    if (pickerRef.current) {
      const activeElement = document.activeElement;
      if (!pickerRef.current.contains(activeElement)) {
        field.onBlur?.();
      }
    }
  };

  const handleDateChange = (newValue: DateTime | null) => {

    const formattedValue =
      newValue && newValue.isValid ? newValue.toISODate() : null;

    field.onChange(formattedValue);
  };

  const parsedValue = value
  ? DateTime.isDateTime(value)
    ? value
    : DateTime.fromISO(value, { locale: 'pt-BR' })
  : null;

  return (
    <LocalizationProvider dateAdapter={AdapterLuxon} adapterLocale="pt-br">
      <div
        ref={pickerRef}
        onBlur={handleBlur}
      >
      <DatePicker
        {...field}
        value={value}
        maxDate={DateTime.now()}
        onChange={handleDateChange}
        slotProps={{
          ...slotProps,
          yearButton: {
            sx: {
              fontSize: 'inherit',
              lineHeight: 'unset',
            },
          },
        }}
        format="dd/MM/yyyy"
        localeText={{
          toolbarTitle: '',
        }}
      />
      </div>
    </LocalizationProvider>
  );
};
