import * as React from 'react';
import Container from '@mui/material/Container';

import { FiltersSchema } from '@/react/components/filters/state/interfaces';
import { FiltersContext } from '@/react/components/filters/state/context';
import { CatalogContext } from '@/react/components/catalog/state/context';
import { getFilterValuesFromUrl } from '@/react/components/filters/state';
import { useScreenSizes } from '@/react/utils/useScreenSizes';
import { useInstitutionsSearch } from '@/react/data/institutions/useInstitutionsData';
import { useBootstrapData } from '@/react/data/bootstrap/useBootstrapData';

import { createSchema } from './catalog-filters.schema';
import { CatalogFiltersDesktop } from './desktop/catalog-filters-desktop.component';
import { CatalogFiltersMobile } from './mobile/catalog-filters-mobile.component';
import { CatalogBanner } from './catalog-banner.component';
import { GtmContext } from '../state/context';
import { PlanFeature } from '@/react/data/bootstrap/interfaces';
import { useFilters } from '@/react/components/filters/state';

const ResponsiveCatalog = ({
  planFeatures,
}: {
  planFeatures: PlanFeature[];
}) => {
  const { isSmallScreen } = useScreenSizes();
  const { values } = useFilters();

  let banner;

  // TODO: this whole block is temporarily hardcoded until we have
  // settings coming from bootstrapData.plan.features (backend)
  if (values['unibrad_included']) {
    banner = {
      altText: 'Unibrad - Pós-graduação por R$32,50',
      backgroundCss: '#CC092F',
      bannerImageSmall:
        'https://assets.galena.com/edupass-api/public/catalog-banners/unibrad_banner/banner1_mobile.png',
      bannerImageMedium:
        'https://assets.galena.com/edupass-api/public/catalog-banners/unibrad_banner/banner1_md.png',
      bannerImageLarge:
        'https://assets.galena.com/edupass-api/public/catalog-banners/unibrad_banner/banner1_lg.png',
    };
  }

  return (
    <>
      {banner && <CatalogBanner {...banner} />}
      {isSmallScreen ? <CatalogFiltersMobile /> : <CatalogFiltersDesktop />}
    </>
  );
};

export const CatalogFilters = () => {
  const { institutions, isFetching: institutionsLoading } =
    useInstitutionsSearch();
  const bootstrapData = useBootstrapData();

  const [filtersSchema, setFiltersSchema] = React.useState<FiltersSchema>(null);

  React.useEffect(() => {
    if (institutionsLoading || bootstrapData.isFetching) {
      return;
    }

    const schema = createSchema(
      bootstrapData.taxonomy,
      institutions,
      bootstrapData.plan
    );
    setFiltersSchema(schema);
  }, [bootstrapData.isFetching, institutionsLoading]);

  if (!filtersSchema) {
    return null;
  }

  const initialValues = getFilterValuesFromUrl();

  return (
    <FiltersContext
      schema={filtersSchema}
      initialValues={initialValues}
      setSchema={setFiltersSchema}
    >
      <GtmContext>
        <CatalogContext>
          <ResponsiveCatalog planFeatures={bootstrapData.plan?.features} />
        </CatalogContext>
      </GtmContext>
    </FiltersContext>
  );
};
