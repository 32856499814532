import React from 'react';
import { Box, Chip, Grid, Typography } from '@mui/material';
import { useRefundStatus } from '@/react/components/refund/utils/use-refund-status';
import { formatDate } from '@/react/utils/date';
import { useRefundRequest } from '@/react/components/refund/refund-request/state/useRefundRequest';

export const RefundUnderReview = () => {
  return (
    <Grid container>
      <Grid item xs={12} md={4}>
        <Box
          sx={({ palette, spacing }) => ({
            color: palette.neutral[800],
            backgroundColor: palette.neutral[200],
            marginBottom: spacing(2),
            paddingLeft: spacing(1),
            paddingRight: spacing(1),
            paddingBottom: spacing('0.5'),
            paddingTop: spacing('0.5'),
            width: 'fit-content',
          })}
        >
          <Typography variant="body3">Aguarde: aprovação pendente</Typography>
        </Box>
      </Grid>
    </Grid>
  );
};

export const RefundDeclined = ({ resourceApproval }) => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={({ spacing }) => ({
          paddingBottom: spacing('0.5'),
          marginBottom: spacing(2),
        })}
      >
        <Chip
          label={`Solicitação cancelada em: ${formatDate(resourceApproval?.refund_request?.decided_at)}`}
          variant="negativeRequestResponse"
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body3">
          {resourceApproval?.refund_request?.decision_comment}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const CorrectionNeeded = ({ resourceApproval }) => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={({ spacing }) => ({
          paddingBottom: spacing('0.5'),
          marginBottom: spacing(2),
        })}
      >
        <Chip label="Correção necessária" variant="actionNeededAfterRevision" />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body3">
          {resourceApproval?.refund_request?.decision_comment
            ? resourceApproval?.refund_request?.decision_comment
            : 'Item não corresponde ao pedido'}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const RefundInvoiceApproved = ({ resourceApproval }) => {
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        justifyContent="center"
        sx={({ palette, spacing }) => ({
          color: palette.neutral[800],
          backgroundColor: 'transparent',
          marginBottom: spacing(2),
          paddingRight: spacing(3),
          paddingBottom: spacing('0.5'),
          paddingTop: spacing('0.5'),
        })}
      >
        <Typography variant="body3">
          {`Aprovado em: ${formatDate(resourceApproval?.refund_request?.decided_at)}`}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const InvoiceValidationSummary = () => {
  const { resourceApproval } = useRefundRequest();
  const { mappedStatus } = useRefundStatus(resourceApproval);

  return (
    <>
      {mappedStatus === 'waitingForDocumentationRevisionStepFour' && (
        <RefundUnderReview />
      )}

      {(mappedStatus === 'canceledRefundRequestStepThree' ||
        mappedStatus === 'declinedRefundRequestStepFour') && (
        <RefundDeclined resourceApproval={resourceApproval} />
      )}

      {mappedStatus === 'needsRefundRequestRevisionStepFour' && (
        <CorrectionNeeded resourceApproval={resourceApproval} />
      )}

      {['pendingRefundPaymentStepFive', 'paidRefundRequestStepFive'].includes(
        mappedStatus
      ) && <RefundInvoiceApproved resourceApproval={resourceApproval} />}
    </>
  );
};
