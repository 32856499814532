export const saveToSession = (key: string, value: any) => {
  window.sessionStorage.setItem(key, JSON.stringify(value));
};

export const getFromSession = (key: string): any => {
  const storedValue =  window.sessionStorage.getItem(key);
  return storedValue ? JSON.parse(storedValue) : null;
};

export const removeFromSession = (key: string) => {
  window.sessionStorage.removeItem(key);
};
