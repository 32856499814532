import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { CatalogSearchRequest, CatalogSearchResponse } from './interfaces';
import { CatalogClient } from '@/react/data/catalog/catalog-client';

export interface UseCatalogSearchResult {
  catalog: CatalogSearchResponse['data'];
  isFetching: boolean;
}

export function useCatalogSearchExcludedFeature(
  requestParams: CatalogSearchRequest,
  enabled: boolean = true
): UseCatalogSearchResult {
  const result = useQuery<CatalogSearchResponse>({
    enabled,
    queryKey: ['useCatalogSearchExcludedFeature', requestParams],
    refetchOnMount: false,
    queryFn: async ({ signal }) => {
      return CatalogClient.getCatalogExcludedFeature({ requestParams, signal });
    },
  });
  return React.useMemo(() => {
    const catalog = result.data?.data || ({} as CatalogSearchResponse['data']);

    return {
      catalog,
      isFetching: result.isFetching,
    };
  }, [result.data, result.isFetching]);
}
