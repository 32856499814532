import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import {
  CalculateCourseRefundableAmountRequest,
  CalculateCourseRefundableAmountResponse,
} from './interfaces';
import { useMemo } from 'react';
import { SubsidyClient } from '@/react/data/subsidy/subsidy-client';

interface calculateRefundableValueForPolicyReturn {
  data: CalculateCourseRefundableAmountResponse | undefined;
  error: AxiosError['response'] | undefined;
  isFetching: boolean;
}

export function calculateRefundableValueForPolicy(
  requestParams: CalculateCourseRefundableAmountRequest | null
) {
  const response = useQuery<CalculateCourseRefundableAmountResponse>({
    queryKey: ['useCatalogSearchExcludedFeature', requestParams],
    refetchOnMount: false,
    queryFn: async ({ signal }) => {
      return SubsidyClient.fetchRefundableAmount({ requestParams, signal });
    },
    enabled: !!requestParams,
  });

  return useMemo((): calculateRefundableValueForPolicyReturn => {
    const error = response.error as AxiosError;

    const result = {
      data: response.data,
      error: error?.response,
      isFetching: response.isFetching,
    };

    return result;
  }, [response.isFetching]);
}
